<template>
  <div class="data-ri">
    <a href="javascript:;" class="data-all" @click="allData">全部</a>
    <div class="yearData">
      <full-calendar :events="dateCountList" class="test-fc" first-day="1" locale="fr" lang="zh"
        @eventClick="eventClick" @unselect="unselect" @dayClick="dayClick" @eventRender="eventRender" :config="config">
      </full-calendar>
      <!-- <full-calendar :events="monthData" class="test-fc" first-day='1' locale="fr" @changeMonth="changeMonth"
        @eventClick="eventClick" @dayClick="dayClick" @moreClick="moreClick" lang="zh"></full-calendar> -->
    </div>
  </div>
</template>
<script>
import bus from '../../../utils/eventBus'
export default {
  data () {
    return {
      time: '',
      num: 0,
      fige: false,
      config: {
        navLinks: true // 允许天/周名称是否可点击
      }
    }
  },
  props: {
    dateCountList: {
      type: Array
    }
  },
  created () {
    bus.$on('refreshAllAuction', () => {
      this.allData()
    })
  },
  methods: {
    navLinks () {
      console.log(111)
    },

    // // 选择月份
    // changeMonth (start, end, current) {
    //   console.log('changeMonth', start.format(), end.format(), current.format())
    // }
    // // 点击事件
    eventClick (event, jsEvent, view) {
      console.log(event, jsEvent, view)
      // this.num++
      // if (this.num === 1) {
      //   console.log(this.num)
      //   this.time = event.start
      //   const el = e.target
      //   el.classList = 'event-item is-start unfllow'
      // } else if (this.num === 2) {
      //   console.log(this.num)
      //   const el = e.target
      //   el.className = 'event-item is-start'
      //   this.num = 0
      //   this.time = ''
      // }
      // console.log(e)
      event.isShow = !event.isShow
      this.time = event.start
      // const el = e.target
      // if (event.isShow === false) {
      //   el.classList.toggle('unfllow')
      // } else {
      //   el.classList.toggle('')
      // }
      this.$emit('timeData', this.time)
      bus.$emit('time', '变化')
    },
    allData () {
      this.$emit('timeData', '')
    },
    unselect (view, jsEvent) {
      // console.log(view, jsEvent)
    },
    // // 点击当天
    dayClick (events) {
      const time = this.$moment(events).format('YYYY-MM-DD')
      this.$emit('timeData', time)

      // console.log('1111', events)
      // var d = new Date(day)
      // this.time = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
      // this.$emit('timeData', this.time)
    },
    // // 查看更多
    // moreClick (day, events, jsEvent) {
    //   console.log('moreCLick', day, events, jsEvent)
    // }
    eventRender (calEvent, element, view) {
      // console.log(calEvent, element, view)
    }
  },
  components: {
    'full-calendar': require('vue-fullcalendar')
  }
}
</script>
<style lang="less" scoped>
  .data-ri {
    position: relative;

    .data-all {
      display: block;
      width: 46px;
      height: 24px;
      border-radius: 4px;
      font-size: 14px;
      color: #cda156;
      line-height: 24px;
      text-align: center;
      border: 1px solid #cda156;
      position: absolute;
      top: 19px;
      right: 51px;
    }

    .yearData {
      /deep/ .events-day:active {
        background-color: #fcf8e3;
      }

      /deep/ .unfllow {
        background: rgba(203, 149, 86) !important;
      }

      /deep/ .day-cell {
        width: 50px;
        /*no*/
        height: 40px;
        /*no*/
        min-height: 0 !important;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        /*no*/
        // margin: 4px;
        // background: transparent;

        border-radius: 4px;
        /*no*/
        margin: 3px;

        /*no*/
        &:hover {
          background: #cda156;
        }
      }

      /deep/ .comp-full-calendar {
        margin-left: 120px;
        margin-right: 27px;
        margin-top: 30px;
        /*no*/
        margin-bottom: 20px;
        /*no*/
        width: 290px;
        /*no*/
        // height: 490px;
        // background: #f9f9f9;

        background: #ffffff;

        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.18);
        /*no*/

        border-radius: 8px;
        /*no*/
      }

      /deep/ .full-calendar-body .dates .dates-events .events-week .events-day {
        min-height: 0;
        height: 40px;
        /*no*/
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        /*no*/
        margin: 3px;
        /*no*/
        border-radius: 4px;
        /*no*/
        // &:hover {
        //   background: #cda156;
        //   // color: #fff;
        // }
      }

      /deep/ .today {
        // background: #fcf8e3;
      }

      /deep/ .full-calendar-body .dates .week-row .day-cell.today {
        background: #fcf8e3;
        // &:hover {
        //   background: #fcf8e3;
        // }
      }

      /deep/ .full-calendar-body .weeks {
        border-top: none;
        border-left: none;
        border-left: none;
        // margin-bottom: 13px;
        border-bottom: none;
      }

      /deep/ .full-calendar-body .dates .week-row {
        border-left: none;
      }

      /deep/ .full-calendar-body .weeks .week {
        border-right: none;
      }

      /deep/ .day-number {
        text-align: center !important;
        font-size: 14px;
        color: #6e5121;
      }

      /deep/ .full-calendar-body .dates .dates-events .events-week .events-day .day-number {
        padding: 4px 5px 0px 4px !important;
      }

      /deep/ .full-calendar-body .dates .dates-events .events-week .events-day .event-box .event-item {
        // width: 48px;
        height: 20px;
        /*no*/
        border-bottom-left-radius: 4px;
        /*no*/
        border-bottom-right-radius: 4px;
        /*no*/
        font-size: 13px;
        font-weight: 500;
        /*no*/
        color: #6e5121;
        // background: #f5edde;
        background: transparent;
        margin-left: 0px;
        // line-height: 20px; /*no*/

        padding: 0;
        // &:hover {
        //   // color: #ffffff;
        //   background: #cda156;
        // }
      }

      /deep/ .full-calendar-header .header-center .prev-month,
      .full-calendar-header .header-center .next-month {
        font-size: 15px;
        /*no*/
      }

      /deep/ .full-calendar-header .header-center .title {
        font-size: 15px;
        /*no*/
      }

      /deep/ .full-calendar-header .header-center .prev-month,
      .full-calendar-header .header-center .next-month {
        font-size: 15px;
        /*no*/
      }
    }
  }
</style>

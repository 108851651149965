<template>
  <div class="container-auctionhome">
    <div class="auctionhome-header">
      <p>
        <template v-if="auctionInfoListDate.time">
          <span style="color: #cda156;">{{auctionInfoListDate.time}}</span>
          <br />
        </template>
        {{ total }}场拍卖会
      </p>
      <div class="pagination-top">
        <a-pagination size="small" :total="total" @change="onChange" v-model="auctionInfoListDate.page"
          :pageSize="auctionInfoListDate.pageSize" />
      </div>
      <div class="auctionhome-header-right">
        <p>
          <span style="font-size:0.091146rem">每页显示</span>
          <a-select placeholder="50" style="width: 120px" v-model="value2" @change="handleChange">
            <a-select-option :value="item.num" v-for="(item, index) in pageNum" :key="index">
              {{ item.num }}
            </a-select-option>
          </a-select>
        </p>
      </div>
    </div>

    <div class="auctionhome-header-mian">
      <div class="auctionhome-header-mian-left">
        <Calendar :dateCountList="dateCountList" v-on:timeData="timeDataSaerch" />
        <!-- 复选框 -->
        <Choose ref="choose" v-on:emitData="showMsgfromChild" />
      </div>
      <div class="auctionhome-header-mian-right">
        <!-- 右功能 -->
        <Auctionhomebidding :list="list" :pageSize="auctionInfoListDate.pageSize" :page="auctionInfoListDate.page"
          @collectAuction="collectAuction" />
      </div>
    </div>
    <div class="auctionhome-header">
      <p>{{ total }}场拍卖会</p>
      <div class="pagination-top" @click="goTop()">
        <a-pagination size="small" :total="total" @change="onChange" v-model="auctionInfoListDate.page"
          :pageSize="auctionInfoListDate.pageSize" />
      </div>
      <div class="auctionhome-header-right">
        <p>
          <span style="font-size:0.091146rem">每页显示</span>
          <a-select placeholder="50" style="width: 120px" v-model="value2" @change="handleChange">
            <a-select-option :value="item.num" v-for="(item, index) in pageNum" :key="index">
              {{ item.num }}
            </a-select-option>
          </a-select>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import Calendar from './components/Calendarchild.vue'
  import Choose from './components/Choose'
  import Auctionhomebidding from './components/Auctionhomebidding'
  import {
    auctionList
  } from '../../api/index'
  export default {
    name: 'Auctionhome',
    components: {
      Choose,
      Auctionhomebidding,
      Calendar
    },
    data() {
      return {
        value: '',
        isChoose: false,
        auctionInfoListDate: {
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          title: '',
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          auctionType: 0,
          page: 1,
          pageSize: 50,
          classifyId: ''
        },
        list: [],
        dateCountList: [],
        total: 0,
        datePickerShow: true,
        feeProps: {},
        auctionhomebidding: [],
        pageNum: [{
            id: 1,
            num: 50
          },
          {
            id: 2,
            num: 75
          },
          {
            id: 3,
            num: 100
          }
        ],
        value2: 50,
        gotop: false,
        top: 0
      }
    },
    created() {
      if (sessionStorage.getItem('msgInfo')) {
        this.auctionInfoListDate.page =
          Number(sessionStorage.getItem('currentPage')) || 1
        this.auctionInfoListDate.pageSize =
          Number(sessionStorage.getItem('select')) || 50
        this.value2 = Number(sessionStorage.getItem('select')) || 50
      } else {
        sessionStorage.removeItem('currentPage')
        sessionStorage.removeItem('select')
        this.auctionInfoListDate.page = 1
        this.auctionInfoListDate.pageSize = 50
        this.value2 = 50
      }
      this.auctionList()
      this.setTimeCount()
      this.setTimeCountTwo()
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    destroyed() {
      sessionStorage.removeItem('msgInfo')
      sessionStorage.removeItem('calendTimeTrue')
    },
    methods: {
      toggePay() {
        this.isChoose = !this.isChoose
      },
      handleChange(value) {
        this.auctionInfoListDate.page = 1
        this.auctionInfoListDate.pageSize = value

        this.auctionList()
      },
      // 子组件的参数
      showMsgfromChild(data) {
        this.auctionInfoListDate.auctionWayId = data.auctionWayId
        this.auctionInfoListDate.brandAuthorId = data.brandAuthorId
        this.auctionInfoListDate.stylePeriodId = data.stylePeriodId
        this.auctionInfoListDate.materialsCraftId = data.materialsCraftId
        this.auctionInfoListDate.placeSourceId = data.placeSourceId
        this.auctionInfoListDate.auctionHouseId = data.auctionHouseId
        this.auctionInfoListDate.classifyId = data.classifyId
        // this.auctionInfoListDate.time = data.time
        this.auctionInfoListDate.page = data.page
        console.log(data.fl)
        if (data.fl !== '变化') {
          this.auctionList()
        }
      },
      // 日历传值
      timeDataSaerch(data) {
        this.auctionInfoListDate.auctionWayId = ''
        this.auctionInfoListDate.brandAuthorId = ''
        this.auctionInfoListDate.stylePeriodId = ''
        this.auctionInfoListDate.materialsCraftId = ''
        this.auctionInfoListDate.placeSourceId = ''
        this.auctionInfoListDate.auctionHouseId = ''
        this.auctionInfoListDate.classifyId = ''
        this.auctionInfoListDate.time = data
        this.auctionInfoListDate.page = 1
        this.auctionList()
      },
      closeFee() {
        this.datePickerShow = true
      },
      getFeeProps() {
        // this.feeProps = {xxx}
      },
      showPicker() {
        this.feeProps = {
          '2021-03-30': 11,
          '2021-04-01': 11,
          '2021-09-29': 11111111
        }
        this.datePickerShow = true
      },
      collectAuction() {
        this.auctionList()
      },
      // 拍品数据列表初始化
      async auctionList() {
        const res = await auctionList(this.auctionInfoListDate)
        if (res.code === 0) {
          this.auctionhomebidding = res.data.list
          this.dateCountList = res.data.dateCountList
          this.total = res.data.count

          this.list = res.data.list
          // for (const i in this.list) {
          //   this.list[i].countDownTime = ''
          //   this.countDown(i)
          // }
        } else {
          this.$message.error(res.msg)
        }
      },
      handleScroll(e) {
        const scrolltop = e.target.scrollTop
        scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
      },
      goTop() {
        // let top = document.documentElement.scrollTop || document.body.scrollTop
        // // 实现滚动效果
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = this.top -= 50
          if (this.top <= 0) {
            clearInterval(timeTop)
          }
        }, 0)
      },
      // 获取页面滚动高度
      scrollHandle(e) {
        this.top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      },
      // 改变页码
      onChange(page) {
        this.auctionInfoListDate.page = page
        // sessionStorage.setItem('page', this.auctionInfoListDate.page)
        this.auctionList()
      },

      // // 倒计时
      // countDownFun (time) {
      //   // console.log(time)
      //   const startTime = new Date() // 当前时间
      //   const end = new Date(time) // 结束时间
      //   // console.log(end)
      //   const result = parseInt((end - startTime) / 1000) // 计算出豪秒
      //   const d = parseInt(result / (24 * 60 * 60)) // 用总共的秒数除以1天的秒数
      //   let h = parseInt((result / (60 * 60)) % 24) // 精确小时，用去余
      //   const m = parseInt((result / 60) % 60) // 剩余分钟就是用1小时等于60分钟进行趣余
      //   let s = parseInt(result % 60)
      //   // 当倒计时结束时，改变内容
      //   if (result <= 0) {
      //     return '委托已结束'
      //   }
      //   if (h < 10) {
      //     h = '0' + h
      //   }
      //   if (s < 10) {
      //     s = '0' + s
      //   }
      //   if (h === 0 && m === 0) {
      //     return s + '秒'
      //   } else if (h === 0) {
      //     return m + '分' + s + '秒'
      //   } else if (d === 0) {
      //     return d + '天' + h + '时' + m + '分' + s + '秒'
      //   } else {
      //     return d + '天' + h + '时' + m + '分' + s + '秒'
      //   }
      // },

      // // 定时器
      // // 页面多个倒计时 归零时清除
      // countDown (i) {
      //   const that = this
      //   const item = that.list[i]
      //   that.list[i].countDownFn = setInterval(() => {
      //     //  console.log(that.countDownFun(item.endTime))
      //     if (that.countDownFun(item.countDownTime) === '委托已结束') {
      //       clearInterval(that.list[i].countDownFn) // 清除定时器
      //     } else {
      //       item.countDownTime = that.countDownFun(item.endTime)
      //       that.$set(
      //         that.list,
      //         item.countDownTime,
      //         that.countDownFun(item.endTime)
      //       )
      //     }
      //   }, 1000)
      // },
      setTimeCount() {
        const Y = new Date().getFullYear()
        const M =
          new Date().getMonth() + 1 - 0 >= 10 ?
          Number(new Date().getMonth()) + 1 :
          '0' + (Number(new Date().getMonth()) + 1)
        const D =
          new Date().getDate() >= 10 ?
          new Date().getDate() :
          '0' + new Date().getDate()
        const h =
          new Date().getHours() >= 10 ?
          new Date().getHours() :
          '0' + new Date().getHours()
        const m =
          new Date().getMinutes() >= 10 ?
          new Date().getMinutes() :
          '0' + new Date().getMinutes()
        const s =
          new Date().getSeconds() >= 10 ?
          new Date().getSeconds() :
          '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].endTime = this.list[j].endTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (this.list[j].endTime !== null && date2 <= this.list[j].endTime) {
              this.$set(
                this.list[j],
                'overtime',
                Date.parse(this.list[j].endTime) - now
              )
              this.$set(
                this.list[j],
                'overD',
                Math.floor(
                  Math.floor(
                    (Date.parse(tmpProducts[j].endTime) - now) /
                    1000 /
                    60 /
                    60 /
                    24
                  )
                )
              )
              this.$set(
                this.list[j],
                'overH',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 / 60) %
                  24
                )
              )
              this.$set(
                this.list[j],
                'overM',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60) % 60
                )
              )
              this.$set(
                this.list[j],
                'overS',
                Math.floor(
                  ((Date.parse(tmpProducts[j].endTime) - now) / 1000) % 60
                )
              )
            }
          }
        }, 1000)
      },
      setTimeCountTwo() {
        const Y = new Date().getFullYear()
        const M =
          new Date().getMonth() + 1 - 0 >= 10 ?
          Number(new Date().getMonth()) + 1 :
          '0' + (Number(new Date().getMonth()) + 1)
        const D =
          new Date().getDate() >= 10 ?
          new Date().getDate() :
          '0' + new Date().getDate()
        const h =
          new Date().getHours() >= 10 ?
          new Date().getHours() :
          '0' + new Date().getHours()
        const m =
          new Date().getMinutes() >= 10 ?
          new Date().getMinutes() :
          '0' + new Date().getMinutes()
        const s =
          new Date().getSeconds() >= 10 ?
          new Date().getSeconds() :
          '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].startTime = this.list[j].startTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (
              this.list[j].startTime !== null &&
              date2 <= this.list[j].startTime
            ) {
              this.$set(
                this.list[j],
                'overtime1',
                Date.parse(this.list[j].startTime) - now
              )
              this.$set(
                this.list[j],
                'overD1',
                Math.floor(
                  Math.floor(
                    (Date.parse(tmpProducts[j].startTime) - now) /
                    1000 /
                    60 /
                    60 /
                    24
                  )
                )
              )
              this.$set(
                this.list[j],
                'overH1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) /
                    1000 /
                    60 /
                    60) %
                  24
                )
              )
              this.$set(
                this.list[j],
                'overM1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) / 1000 / 60) % 60
                )
              )
              this.$set(
                this.list[j],
                'overS1',
                Math.floor(
                  ((Date.parse(tmpProducts[j].startTime) - now) / 1000) % 60
                )
              )
            }
          }
        }, 1000)
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-auctionhome {
    .el-select {
      width: 100px;
      height: 50px;
    }

    // /deep/ .ant-pagination-item-active {
    //   border: none;
    // }
    /deep/ .ant-select {
      width: 80px !important;
    }

    /deep/ .ant-select-selection__placeholder {
      color: rgba(0, 0, 0, 0.65);
    }

    #components-pagination-demo-mini .ant-pagination:not(:last-child) {
      margin-bottom: 24px;
    }

    /deep/ .ant-pagination-item-active a {
      color: #fff;
      background: #cda156;
    }

    /deep/ .ant-pagination-item a:hover {
      color: #000;
    }

    /deep/ .ant-pagination-item {
      border: 1px solid #ccc !important;
      margin: 0px 5px;

      a {
        font-size: 15px;
      }
    }

    /deep/ .ant-pagination-prev {
      border: 1px solid #ccc !important;
      margin-right: 5px;
    }

    /deep/ .ant-pagination-next {
      border: 1px solid #ccc !important;
      margin-left: 5px;
    }

    /deep/ .anticon {
      vertical-align: 0;
    }

    /deep/ .ant-pagination-item-active {
      background: #cda156;
    }

    .pagination {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-pagination {
      flex: 1;
      text-align: right;
      margin-right: 102px;
    }

    /deep/ .ant-pagination {
      font-size: 18px;
    }

    .auctionhome-header {
      height: 70px;
      align-items: center;
      display: flex;
      margin: 0 100px;
      justify-content: space-between;
      border-bottom: 1px solid #dedede;
      border-top: 1px solid #dedede;

      p:nth-child(1) {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 400;
        color: #333;
      }

      .auctionhome-header-right {
        display: flex;
        align-items: center;

        p:nth-child(1) {
          span {
            font-size: 20px;
            color: #333;
            font-weight: 400;
            padding-right: 10px;
          }
        }

        p:nth-child(2) {
          span {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            padding-right: 10px;
          }

          a {
            color: #333;

            i {
              font-size: 20px;
            }
          }
        }
      }
    }

    .auctionhome-header-mian {
      display: flex;
    }
  }
</style>

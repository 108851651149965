<template>
  <div class="container-choose">
    <div class="choose-left">
      <div class="choose-emty">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >重置</el-checkbox
        >
      </div>
      <div class="choose-purchase" v-if="attributeList.valueAttribute !== null">
        <p>{{ attributeList.name }}</p>
        <div class="checked">
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange($event, attributeList.id)"
          >
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in attributeList.valueAttribute"
              :key="index"
              :checked="checkAlle"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="brandAuthorId.valueAttribute !== null">
        <p>{{ brandAuthorId.name }}</p>
        <div class="checked">
          <el-checkbox-group
            v-model="checkedCitiesTwo"
            @change="handleCheckedCitiesChange($event, brandAuthorId.id)"
          >
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in brandAuthorId.valueAttribute"
              :key="index"
              :checked="checkAlle"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="stylePeriodId.valueAttribute !== null">
        <p>{{ stylePeriodId.name }}</p>
        <div class="checked">
          <el-checkbox-group
            v-model="checkedCitiesSthree"
            @change="handleCheckedCitiesChange($event, stylePeriodId.id)"
          >
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in stylePeriodId.valueAttribute"
              :key="index"
              :checked="checkAlle"
            >
              {{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="classFig.valueAttribute !== null">
        <p>{{ classFig.name }}</p>
        <div class="checked">
          <el-checkbox-group
            v-model="checkedCitiesFour"
            @change="handleCheckedCitiesChange($event, classFig.id)"
          >
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in classFig.valueAttribute"
              :key="index"
              :checked="checkAlle"
            >
              {{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auctionAttribute } from '../../../api/index'
import bus from '../../../utils/eventBus'
export default {
  name: 'Choose',
  components: {},
  inject: ['reload'],
  data () {
    return {
      attributeList: [],
      brandAuthorId: [],
      stylePeriodId: [],
      checkAll: false,
      checkedCities: [],
      checkedCitiesTwo: [],
      checkedCitiesSthree: [],
      checkedCitiesFour: [],

      classFig: [],
      // cities: cityOptions,
      isIndeterminate: true,
      checkAlle: false,
      emitData: {
        time: '',
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        classifyId: '',
        page: 1,
        fl: ''
      },
      height: 'height:50px;'
    }
  },
  created () {
    this.auctionAttribute()
    // this.forId()
  },
  mounted () {
    bus.$on('time', value => {
      this.handleCheckAllChange(value)
    })
  },
  methods: {
    handleCheckAllChange (val) {
      this.checkedCities = []
      this.checkedCitiesTwo = []
      this.checkedCitiesSthree = []
      this.checkedCitiesFour = []
      this.emitData.time = ''
      this.emitData.auctionWayId = ''
      this.emitData.placeSourceId = ''
      this.emitData.stylePeriodId = ''
      this.emitData.classifyId = ''
      this.emitData.auctionHouseId = ''
      this.emitData.fl = val
      this.$emit('emitData', this.emitData)
    },
    handleCheckedCitiesChange (value, id) {
      if (id === 1) {
        this.emitData.auctionWayId = value.toString()
        sessionStorage.setItem('auctionWayId', this.emitData.auctionWayId)
      } else if (id === 5) {
        this.emitData.placeSourceId = value.toString()
        console.log(value)
        sessionStorage.setItem('placeSourceId', this.emitData.placeSourceId)
      } else if (id === 6) {
        this.emitData.auctionHouseId = value.toString()
        sessionStorage.setItem('auctionHouseId', this.emitData.auctionHouseId)
      } else if (id === 100) {
        this.emitData.classifyId = value.toString()
        sessionStorage.setItem('classifyId', this.emitData.classifyId)
      }
      this.emitData.fl = 1
      this.$emit('emitData', this.emitData)
    },
    forId () {
      // const auctionWayId = sessionStorage.getItem('auctionWayId')
      // const placeSourceId = sessionStorage.getItem('placeSourceId')
      // this.checkedCitiesTwo = placeSourceId.split(',') || []
      // console.log()
      // this.checkedCities = .split(',')
    },
    // 数据初始化列表
    async auctionAttribute () {
      const res = await auctionAttribute({
        id: 0,

        userid: Number(localStorage.getItem('accessId')) || 0,
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken') || 0,
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        time: '',
        page: 1,
        pageSize: 40,
        title: '',
        classifyId: '',
        twoClassifyId: '',
        auctionType: 0
      })
      if (res.code === 0) {
        this.attributeList = res.data.list[0]
        this.brandAuthorId = res.data.list[1]
        this.stylePeriodId = res.data.list[2]
        this.classFig = res.data.list[3]
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container-choose {
  width: 450px;
  display: flex;
  flex-direction: column;
  /deep/ .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #cda156;
  }
  /deep/ .el-checkbox {
    margin-bottom: 10px;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #cda156;
    border-color: #cda156;
  }
  /deep/ .el-checkbox__inner:hover {
    border-color: #cda156;
  }
  .choose-emty {
    width: 280px;
    height: 44px;
    border: 1px solid #dedede;
    line-height: 44px;
    margin: 20px 0 30px 135px;
    font-weight: 400;
    /deep/ .el-checkbox__inner {
      display: none;
    }
    /deep/ .el-checkbox__label {
      color: #cda156;
    }
    a {
      color: #6e5121;
      font-size: 16px;
    }
  }
  .choose-purchase {
    display: flex;
    flex-direction: column;
    // align-items: self-end;
    margin-left: 90px;
    text-align: left;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
    max-height: 200px;
    p {
      margin-bottom: 10px;
    }
    p:nth-child(1) {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .checked {
      display: flex;
      flex-direction: column;
      // align-items: self-end;
      overflow: hidden;
      // height: 180px;
    }
    .checked:hover {
      white-space: nowrap; //阻止文本换行
      overflow: auto;
    }

    .choose-more {
      margin-top: 10px;
      width: 370px;
      text-align: left;
      // height: 42px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 20px;
      line-height: 42px;
      a {
        font-size: 14px;
        color: #cda156;
      }
      i {
        font-size: 14px;
        color: #cda156;
      }
    }
  }
  .choose-line {
    margin: 20px 0 20px 90px;
    width: 370px;
    border: 1px solid #d8d8d8;
  }
}
</style>
